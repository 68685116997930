.area-twocolumnwysiwyg {
  @include media-breakpoint-up(md) {
    .two-column-wysiwyg {
      &-right {
        padding-left: 5rem;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          height: 100%;
          border-left: 2px solid $color-grey;
        }
        @media screen and (max-width: 960px)  {
            padding-left: 2rem;
        }
      }
      &-left {
        padding-right: 5rem;
      }
    }
  }

  @media (max-width: 765px) {
    .two-column-wysiwyg {
      &-left {
        margin-bottom: 1rem;
      }
    }
  }
}