#siteWrapper {
  max-width: $site-wrapper-width;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  &.top-navigation {
  }
}

span.icon {
  width: 15px;
  height: 15px;
  display: block;

  svg {
    max-width: 100%;
    max-height: 100%;
    //width: 100%;
    //height: 100%;
  }
}

.btn {
  &.btn-primary {
    background-color: $color-cherryred;
    border: none;
    position: relative;
    //border-radius: 0;

    &:hover, a:hover {
      border: none;
      color: $color-camo-green;
      text-decoration: none;

      &:after {
        opacity: 1;
      }
    }

    &:focus, &:active {
      //@include red-gradient-bg;
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  &.btn-secondary {
    background-color: $color-camo-green;
    border: none;

    &:hover {
      color: $color-cherryred;
    }
  }

  &.btn-ghost {
    border: 1px solid black;
    color: $color-camo-green;

    &:hover {
      color: $color-cherryred;
    }
  }

  &.btn-icon-white {
    background: $color-white;
    border: 1px solid $color-btn-white-border;
    color: $color-cherryred;
    padding: 8px 10px;

    span {
      display: block;
      float: left;
      line-height: 28px;
      font-weight: bold;

      &.icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      svg {
        fill: $color-cherryred;
      }
    }

    &:hover {
      border: 1px solid $color-cherryred;
    }
  }
}

.location-address {
  &__email {
    padding-top: 15px;

    a {
      position: relative;
      padding-left: 25px;
      color: $color-cherryred;
      @include transition(all 300ms);

      svg {
        fill: $color-cherryred;
        @include transition(all 300ms);
      }

      &:hover {
        @include transition(all 300ms);
        color: $color-dark-red;
        text-decoration: none;

        svg {
          @include transition(all 300ms);
          fill: $color-dark-red;
        }
      }
    }

    span.icon {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

body {
  &[data-layout="messe"],
  &[data-layout="fair"]
  {
    .d-none-layout-fair {
      display: none !important;
    }

    span.icon {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

/******************
 * COOKIE CONSENT
 ******************/

body.cookieconsent-blockingmode {
  &--auto {
    .cookieconsent-optout-marketing {
      display: block;
    }
    .d-block-cookieconsent-blockingmode--auto {
      display: block !important;
    }
  }
  &--manual {
    .cookieconsent-optout-marketing {
      display: block;
    }
    .d-block-cookieconsent-blockingmode--manual {
      display: block !important;
    }
  }
}

.cookieconsent-optout-marketing {
  cursor: pointer;
  display: none;

  &:hover {
    .cookieconsent-optout {
      &__text {
        opacity: 1;
      }
    }
  }

  &--shadow {
    background: rgba(0,0,0, 0.3);

    .cookieconsent-optout {
      &__text {
        opacity: 1;
      }
    }
  }

  &--full-size {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    padding: 15px;

    > a {
      height: 100%;
    }
    .cookieconsent-optout__content {
      height: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: 1px solid $color-grey;
    }
  }
}

.cookieconsent-optout {
  &__wrapper {
    position: relative;
  }
  &__content {
    button {
      background: none;
      border: none;
    }
    .ytp-large-play-button {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 68px;
      height: 48px;
      margin-left: -34px;
      margin-top: -24px;
      -moz-transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
      -webkit-transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
      transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
      z-index: 63;
    }
  }

  &__text {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 30%;
    width: 100%;
    padding: 5px 15px;
    display: block;
    text-align: center;
    color: #ffffff;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    text-shadow: 4px 1px 6px rgba(14,13,13,0.6);
    &--form {
      top: 5%;
    }
  }
}
