.unsubscribe-newsletter {
	margin-top: 8.13rem;
	padding-bottom: 0 !important;

	input:not([type="checkbox"]) {
		width: 100%;
		display: block;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		color: $color-black;
		background-color: $color-white;
		box-shadow: none;
		outline: none;
		border: 1px solid $color-dark-grey !important;
	}

	.checkbox {
		> div {
			padding-left: 20px;
			position: relative;

			input {
				position: absolute;
				top: 5px;
				left: 0;
			}
		}
	}
}