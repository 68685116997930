.area-locations {

  .locations-wrapper{
    @media(max-width: 480px){
      text-align: center;
    }
  }

  .footer-contact {
    background-color: $color-beige;
    padding: $brick-padding 0;

    &__address {
      color: $color-camo-green;
      font-weight: $font-weight-medium;
      font-size: $font-size-small;

      img {
        margin-bottom: 15px;
      }
      @media(max-width: 768px){
        margin: 0 0 40px;
      }
    }
  }

  .location-address {

    a:not(.email) {
      @include transition(all 300ms);
      color: $color-camo-green;

      &:hover {
        text-decoration: none;
        color: $color-cherryred;
        @include transition(all 300ms);
      }
    }
  }
}
