.bootstrap-select {
  position: relative;
  //font-family: $font-family-base;

  .btn-default.dropdown-toggle {
    outline: 0 !important;
    border-radius: 0;
    border: none;
  }

  .dropdown-menu {
    border-radius: 0;

    li {
      &.selected a {
        //font-weight: $font-weight-bold;
      }
    }
  }

  > select {
    display: none !important;
  }
}