.area-video {
  &__content {

  }

  &__video-wrapper {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    height: 0;
    overflow: hidden;
    padding-top: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}