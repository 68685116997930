.gallery__wrapper {

  position: relative;
  float: none;
  margin: 0 auto;

  .center-col {
    float: none;
    margin: 0 auto;
  }

  .gallery__item {
    p {
      margin-bottom: 0;
      margin-top: 1rem;
      margin-left: 0.4rem;
      color: $color-camo-green;
      line-height: 1.3;
      font-weight: bold;
    }

    img {
      width: 100%
    }
  }

  .arrows {

    @media(max-width: 1400px){
      display: none;
    }

    .slick-prev {
      z-index: 1;
      background: url("/static/icomoon/SVG/arrow_left_m.svg") no-repeat;
      background-size: 1rem;
      height: 2rem;
      width: 1rem;
      position: absolute;
      top: 50%;
      left: -10%;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      opacity: 0.3;

      &:before {
        content: none;
      }
    }

    .slick-next {
      z-index: 1;
      background: url("/static/icomoon/SVG/arrow_right_m.svg") no-repeat;
      background-size: 1rem;
      height: 2rem;
      width: 1rem;
      position: absolute;
      top: 50%;
      right: -10%;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      opacity: 0.3;

      &:before {
        content: none;
      }
    }
  }
  .slick-slider {
    margin-bottom: 0;
  }

  .slick-dots {
    position: static;
    bottom: auto;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    li {
      margin: 0;
      width: 26px;
    }

    li button:before
    {
      line-height: 1.25rem;
      color: $color-pinkish-grey;
      opacity: 1;
      height: 0.75rem;
      width: 0.75rem;
      top: -0.0625rem;
      left: 0.1875rem;
    }
    li button {
      border-radius: 50%;
      height: 1rem;
      width: 1rem;

    }

    li.slick-active button{
      background-image: url("/static/icomoon/SVG/dot_navigation_big_m.svg");
      background-size: 0.8125rem;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.32;


      &:before{
        content:"";
      }
    }
  }
}

