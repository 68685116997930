.footer-contact-form {

  .form-control {
    border-radius: 0;
    border: none;

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
      outline: 0;
    }

  }

  form {
    label {
      display: none;
    }

    [for="footer_contact_privacy"],
    [for="footer_contact_contactConsent"]
    {
      display: inline-block;
      margin-left: 4px;
      margin-top: 4px;
    }

    input {
      border-radius: 0;
      border: none;

      &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        outline: 0;
      }
    }
  }
  .datasecurity {
    position: relative;
    top: 0;
    left: 0;
    color: black;
    font-size: 0.75rem;
  }
}