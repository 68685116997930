.area-cta {
  background: $color-grey;
  &__content {
    .cta-content {
      &__title {

      }
      &__text {

      }
      &__button {

      }
    }
  }
}