$mobile-menu-button-width: 30px;
$mobile-menu-button-height: 30px;

body:not(.home) {

	header.is-top{
		.mobile-menu-button {
		}
	}

	header:not(.is-top) {
		.mobile-menu-button {
			margin-top: 23px;
			top: 0;
		}
	}
}

.mobile-menu-button {
	z-index: 10;
	width: $mobile-menu-button-width;
	height: $mobile-menu-button-height;
	position: relative;
	margin-left: 1.25rem;
	transform: translate(0, -50%) rotate(0deg);
	top: 50%;
	cursor: pointer;

	span {
		border-radius: $mobile-menu-button-height / 5;
		left: 0;
		display: block;
		position: absolute;
		height: $mobile-menu-button-height / 5;
		width: 50%;
		opacity: 1;
		transform: rotate(0deg);
		@include transition(.25s ease-in-out);
		background-color: $color-cherryred;

		&:nth-child(even) {
			left: 50%;
			border-radius: 0;
		}

		&:nth-child(odd) {
			left: 0;
			border-radius: 0;
		}

		&:nth-child(1), &:nth-child(2) {
			top: 0;
			height: 0.4rem;
		}

		&:nth-child(3), &:nth-child(4) {
			top: ($mobile-menu-button-height / 5) * 1.8;
			//margin-top: 0.2rem;
			height: 0.35rem;
		}

		&:nth-child(5), &:nth-child(6) {
			top: ($mobile-menu-button-height / 5) * 3.5;
			//margin-top: 0.2rem;
			height: 0.4rem;
		}
	}
}

body.mobile-menu-open {
	.mobile-menu-button {
		opacity: 0;
	}
}
