.area-blurb {
  svg {
    height: 64%;
    fill: $color-cherryred;
    position: absolute;
    top:  0%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.blurb {
  margin-top: 3rem;
  padding-top: 3rem;

  &__headline {
    color: $color-camo-green;
    font-weight: bold;
    font-size: 1.3rem;
  }

  a {
    color: $color-cherryred;
  }

  a:hover {
    text-decoration: none;
    color: $color-camo-green;
  }
}