.area-contactperson {

  padding-bottom: 0;

  .brick__header{
    margin-bottom: 20px;
    &--headline {
      &-inner{
        margin-bottom: 74px;
      }
    }
    &--sub-headline {
      &-inner {
        @include font-teaser-sub-headline;
      }
    }
    &--info-text{
      margin-top: 20px !important;
      @include  font-teaser-text;

    }

  }

  .contact-person {
    &__wrapper{
      margin: 0 auto;
    }

    &__items{
      text-align: center;
      margin-bottom: $grid-gutter-width/2;
    }

    &__text{
      &-top{
        text-align: center;
        margin-bottom: 76px;
        strong {
          @include font-teaser-text;
        }
      }
      &-bottom{
        text-align: center;
        margin-bottom: 60px;
        strong {
          @include font-teaser-text;
        }
      }
    }

    &__bottom-line {
      border-bottom: 2px solid $color-grey;
    }

    .teaser-object{
      margin: 0 50px 30px;
      &__image{
        &-person {
          height: auto;
          object-fit: cover;
          width: 100%;
         // height: 190px;
        //  width: 190px;
          margin-bottom: 30px;
          border-radius: 50%;
        //  width: 12.5rem;
          margin-left: auto;
          margin-right: auto;

          @include media-breakpoint-down(sm) {
            max-width: 160px;
          }
        }
      }
      &__info{
        &-wrapper{
          display: flex;
          flex-direction: column;
        }
      }
      &__position{
        order: 3;
        p{
          margin-bottom: 0;
        }
      }
      &__phone{
        order: 4;
        @include  font-teaser-footer;
      }
      &__sub-headline{
        order: 2;
        @include  font-teaser-footer;
      }
      &__headline{
        @include  font-teaser-text;
        order: 1;
      }
      &__mail{
        @include  font-teaser-footer;
        order: 5;
        overflow-wrap: break-word;
      }
      @media(min-width: 768px) and (max-width: 992px){
        margin: 0 15px 40px;
      }
      @media(max-width: 480px){
        margin: 0 15px 40px;
      }
    }
  }
}