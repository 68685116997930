.home {
  header {
    height: 8.13rem;
    line-height: 8.13rem;
    padding-top: 0;
    @include transition(all 300ms);

    .mobile-menu-button {
      top: 70px;
    }

    .logo-wrapper {
      text-align: center;

      a {
        vertical-align: middle;
      }
    }

    &:not(.is-top) {
      .language-switch {
        vertical-align: unset;
      }

      .logo-wrapper a {
        @include media-breakpoint-up(sm) {
          position: absolute;
          right: 0;
          left: 0;
          top: 28px;
          transform: translateX(50px);
        }

        @include media-breakpoint-up(lg) {
          transform: translateX(90px);
        }

        @include media-breakpoint-up(xl) {
          transform: translateX(135px);
        }
      }

      .mobile-menu-button {
        top: 31px;
      }

      .logo-wrapper {
        position: relative;
        text-align: center;
        max-width: 100%;

        @include media-breakpoint-up(md) {
          margin-left: 2rem;
        }

        a {
          width: 100%;
        }

        &__logo {
          width: 100%;
          max-height: 5rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @media (max-width: 500px) {
            margin-top: 0;
          }
        }
      }
    }
    .top-navigation-wrapper {
      margin-top: 0;
    }

    &.editmode {
      position: relative;
    }
  }
}

body:not(.home) {
  .mobile-menu-button {
    top: 23px;
  }

  .logo-wrapper{
    text-align: center;
    a{
      width: 100%;

      @include media-breakpoint-up(sm) {
        position: absolute;
        right: 0;
        left: 0;
        transform: translateX(50px);
      }

      @include media-breakpoint-up(lg) {
        transform: translateX(90px);
      }

      @include media-breakpoint-up(xl) {
        transform: translateX(135px);
      }

      img {
        top: 5px;
      }
    }
  }

  .top-navigation-wrapper {
    margin-top: 5px;
  }

  header.smart-hide:not(.is-top) {
    line-height: 0;

    .logo-wrapper__logo {
      opacity: 1;

      @media(max-width: 500px){
        top: 0.6rem;
        max-width: 100%;
        margin-left: 1rem;
      }
    }
  }

  header.smart-hide.is-top {
    .logo-wrapper__logo{
      @media (max-width: 500px) {
        top: 0.6rem;
        margin-left: 1rem;
        }
    }
  }

  .language-switch {
    vertical-align: text-top;
  }

}


header {
  position: fixed;
  top: 0;
  z-index: 200;
  max-width: 1920px;
  width: 100%;
  height: $header-height-desktop;
  background-color: #FFFFFF;
  box-shadow: 0 10px 25px rgba(40, 40, 40, 0.1);
  left: 50%;
  @include translate-rotate(-50%,0,0);
  padding-top: 0.5rem;
  @include transition(all 300ms);

  &.animated {
    @include transition(all 250ms);
  }

  &.nav-up {
    top: -8.75rem;
    &.open {
      top: 0;
    }
  }

  .logo-wrapper {

    a {
      display: inline-block;
    }

    &__logo {
      max-height: 100%;
      display: block;
      top: 0.6rem;
      position: relative;
      width: 100%;

      @include transition(opacity 250ms);
      opacity: 1;

      &--slim {
        @include transition(opacity 250ms);
        opacity: 0;

      }
    }
  }

  &.top-navigation {
    // height: $extended-header-height-desktop;
    &.nav-up {
      top: -$extended-header-height-desktop;
      &.open {
        top: 0;
      }
    }
    .logo-wrapper {
      height: ($extended-header-height-desktop - ($header-space * 2));
    }
    .main-navigation {
      margin-top: $top-nav-space;
    }
  }
  .top-navigation-wrapper {
    text-align: right;

    @include media-breakpoint-up(lg) {
      //padding-top: 0.5rem;
      //height: $top-nav-height;
      //line-height: $top-nav-height;
    }

    .search-bar {
      display: inline-block;
    }
    .top-navigation {
      display: inline-block;
      position: relative;
      &__list {

      }
      &__item {
        background-color: $color-cherryred;
        border-color: $color-cherryred;
        width: 6.5rem;
        height: 2.1rem;
        margin-right: rem-calc(15);
        //margin-left: rem-calc(15);

        a {
          color: white;
          font-size: 1.13rem;
          font-weight: bold;
          position: relative;
          top: -4px;
        }
      }
    }
    .language-switch {
      display: inline-block;
      position: relative;
      color: #b7b6b0;
      vertical-align: middle;
      padding-right: rem-calc(15);
      font-size: rem-calc(12);

      @include media-breakpoint-up(md) {
        font-size: rem-calc(15);
      }

      a {
        color: $color-pinkish-grey;
      }
    }
  }

  /**
  ** CHANGE HEADER IMAGE DEPENDING ON /PATH
  */
  &.smart-hide {
    &.is-top {
      .logo-wrapper__logo {
        opacity: 1;

        &--slim {
          opacity: 0;

        }
      }
    }

    &:not(.is-top) {
      height: $header-height-desktop;


        line-height: $header-height-desktop;


      .logo-wrapper__logo {
        opacity: 0;

        &--slim {
          opacity: 1;

        }
      }

      ~ .header-stage {
        margin-top: $header-height-desktop;
      }
    }
  }
}

.main-navigation {
  &__item {
    &--sub-navigation {
      display: none;
    }
  }
}