.area-newsteaser {
  padding: 40px 0;
  .h2{
    @include  font-h2;
  }
  .h1{
    @include  font-h1;
  }
  .news-teaser__wrapper-outer{
    position: relative;
  }
  .news-teaser-item{
    display: flex;
    &__image{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &-info {
      &__title {
        @include font-h3;
        color: $color-camo-green;
      }
      &__date {
        @include copy-s-black-bold;
        color: $color-camo-green;

      }
      &__text {
        @include copy-s-black-regular;
        display: inline-block;
        margin-bottom: 10px;
        overflow: hidden;
        /*
        p {
          @include multiLineEllipsis($lineHeight: 26px, $lineCount: 3, $bgColor: white);
          margin-bottom: 10px;
        }
        */
      }
    }
    &__read-more{
      &--style{
        @include  copy-s-black-bold;
        color:$color-cherryred;
        .icon-rue_m{
          fill: $color-cherryred;
          display: inline-block;
          margin-right: 8px;
          @media only screen and (min-width: 768px) {
            position: relative;
            //top: 1.5px;
          }
        }
        &:hover{
          text-decoration: none;
          color: $color-camo-green;
          .icon-rue_m{
            fill: $color-camo-green;
          }
        }
      }
    }
  }
  .news-teaser{

    &__items{
    display: flex;
    }

    &__arrows {
      position: absolute;
      top: 60%;
      margin-top: -40px;
      @media(max-width: 1400px){
        display: none;
      }

      .news-prev {
        z-index: 1;
        background: url("/static/icomoon/SVG/arrow_left_m.svg") no-repeat;
        background-size: 1rem;
        height: 2rem;
        width: 1rem;
        left: -100px;
        position: relative;
        cursor: pointer;
        border: none;
        outline: none;
        opacity: 0.3;
      }

      .news-next {
        z-index: 1;
        background: url("/static/icomoon/SVG/arrow_right_m.svg") no-repeat;
        background-size: 1rem;
        height: 2rem;
        width: 1rem;
        left: 1200px;
        position: relative;
        cursor: pointer;
        border: none;
        outline: none;
        opacity: 0.3;
      }
    }
  }
  .cta-button-item{
    a {
      @include font-teaser-text;
      color: white;
      padding: 10px 35px;
    }
  }
  .brick__footer{
    margin-top: 60px;
  }
}