.home {
  section.header-stage {
    margin-top: 8.13rem;
  }
}

section.header-stage {
  padding: 0;
  margin-top: $header-height-desktop;
  transition: margin-top 0.3s;
  .stage-snippet {
    position: relative;
    &--mainPage {
      height: calc(100vh - #{$extended-header-height-desktop});
      .stage-snippet__slide-background {
        height: calc(100vh - #{$extended-header-height-desktop});
      }
      .stage-snippet__slide-video{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /*
        min-width: 100%;
        min-height: 100%;
        left: 50%;
        @include translate-rotate(-50%, 0, 0);
        */
      }
    }
    &--searchPage {
      height: $fix-slider-height;
      .stage-snippet__slide-background {
        height: $fix-slider-height;
      }

      .stage-snippet__slide-content-headline {
        top: -7rem;
        position: relative;
      }

      .stage-overlay {
        max-width: 60rem;
        height: auto;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .search-object {
        bottom: 3rem;
      }
    }
    &--objectPageNoTabs {
      height: $fix-slider-height;

      @include media-breakpoint-up(md) {
        height: $fix-slider-height-desktop;
      }

      .stage-snippet__slide-background {
        height: $fix-slider-height;

        @include media-breakpoint-up(md) {
          height: $fix-slider-height-desktop;
        }
      }

      .stage-overlay {
        display: none;
      }

      .search-object {
        display: none;
      }

    }

    &--objectPage {
      height: $fix-slider-height;

      @include media-breakpoint-up(md) {
        height: $fix-slider-height-desktop;
      }

      .stage-snippet__slide-background {
        height: $fix-slider-height;

        @include media-breakpoint-up(md) {
          height: $fix-slider-height-desktop;
        }
      }

      .stage-snippet__slide-content-headline {
        top: -7rem;
        position: relative;
      }

      .stage-overlay {
        display: none;
      }

      .search-object {
        display: none;
      }

      .stage-navigation__list {
        position: fixed;
        left: 0;
        width: 100%;
        background-color: $color-camo-green;
        text-align: center;
        max-width: 1920px;
        transform: translateX(-50%);
        left: 50%;

        li {

          font-size: 1.25rem;
          font-weight: bold;
        }

        li:not(:last-child) {
          margin-right: 3rem;
          margin-top: 1rem;
          margin-bottom: 0.25rem;
        }

        a {
          color: $color-cherryred;
        }
      }

      .stage-footer:hover {
        color: $color-cherryred;
      }
    }

    &__slider {
      padding: 0;
      margin: 0;
      height: 100%;
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
        }
      }
    }
    &__slide {
      position: relative;
      height: 100%;
      outline: none;
      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
      }
      &-content {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        padding: 40px;
        top: 50%;
        transform: translateY(-50%);
        &-subHeadline {
          font-size: 35px;
          color: $stage-text-color;
        }
        &-headline {
          font-size: 45px;
          color: $color-cherryred;
        }
        .stage-snippet__slide-buttons {
          .cta-button-item {
            margin: 0 10px;
            text-align: center;
          }
        }
        .stage-snippet__slide-quick-links {
          margin-top: 40px;
          .quick-link-item {
            width: 120px;
            margin: 10px;
            &__icon {
              text-align: center;
              margin-bottom: 10px;
              svg {
                width: 80px;
                height: 80px;
                color: $stage-text-color;
              }
            }
            &__text {
              text-align: center;
              color: $stage-text-color;
            }
          }
        }
      }
    }
    &__slide-down-button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 25px;
      cursor: pointer;
      svg {
        width: 60px;
        height: 60px;
        color: $stage-navigation-color;
      }
    }
    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      padding: 0;
      z-index: 100;
      li {
        display: inline-block;
        button {
          width: 12px;
          height: 12px;
          background: $stage-navigation-color;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
        &.slick-active button {
          background: $color-cherryred;
        }
      }
    }
  }
}

#siteWrapper.top-navigation {
  section.header-stage {
    .stage-snippet--full {
      height: calc(100vh - #{$extended-header-height-desktop});
      .stage-snippet__slide-background {
        height: calc(100vh - #{$extended-header-height-desktop});
      }
    }
  }
}

.stage-overlay {
  position: absolute;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  transition: .5s ease;
  background-color: rgba($color-offwhite, 0.8);
  z-index: 1;
  @include media-breakpoint-down(md) {
    font-size: 13px;
  }
}

.search-object {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  bottom: -3rem;

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-pinkish-grey;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $color-pinkish-grey;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $color-pinkish-grey;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $color-pinkish-grey;
  }

  &-button {
    background-color: $color-cherryred;
    border-color: $color-cherryred;
    color: white;
    font-size: 1.13rem;
    font-weight: bold;
  }

  input {
    height: 40px;
  }
}




