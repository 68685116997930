.mobile-navigation {
  background-color: $color-camo-green;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 10px 0 rgba($color-grey, 0.3);
  padding: $grid-gutter-width / 2 0;
  width: 300px;
  max-height: 100vh;
  z-index: 220;
  transition: transform 0.4s ease-out;
  transform: translate(-120%, 0);

  &__close-helper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 219;
  }

  &__nav {
    color: $color-offwhite;
    margin-top: 15rem;

    @media (max-height: 1000px) {
      margin-top: 5rem;
    }

    ul.navigation.main-navigation__list {
      padding-left: 30px;
    }

    .main-navigation__item {
      padding: 0.6rem 0.75rem;
      padding-left: 0px;

      a {

        font-weight: bold;
        font-size: 1.3rem;
        color: $color-offwhite;

        &.open{
          color: $color-cherryred;
        }

      }

      a:hover {
        color: $color-cherryred;
        text-decoration: none;
      }
    }

    .main-navigation__item:hover{
      color: $color-cherryred;
    }

    .main-navigation__single-page-link {
      a {
        font-size: 1.3rem;
      }
    }

    .main-navigation__list {
      list-style: none;
    }

    .main-navigation__item--sub-navigation {
      padding-top: 0.75rem;
      padding-left: 1rem;
      line-height: 1.5rem;
    }

    .main-navigation__sub-navigation-item {
      padding-top: 0;
      padding-bottom: 0;
      font-weight: normal;
      margin-left: 1rem;
    }

    .main-navigation__sub-navigation-item a {
      font-weight: normal;
      margin-top: 0.1rem;
      font-size: 1rem;
    }
  }

  &__cross {
    position: absolute;
    top: 10px;
    left: 20px;
    cursor: pointer;
    font-size: 2rem;
  }

  &.is-top.is-home {
    .mobile-navigation__cross {
      top: 40px;
    }
  }

  .navigation {
    padding-bottom: 20px;
    max-height: 70vh;
    overflow-y: hidden;

  }

  .navigation:hover {
    overflow-y: auto;
  }
}

body.mobile-menu-open {
  .mobile-navigation {
    transform: translate(0%, 0);

    &__close-helper {
      display: block;
    }
  }

  .social-media-wrapper {
    padding-left: 20px;
    position: absolute;
    bottom: 0;

    :not(:last-child) {
      margin-right: 0.5rem;
    }

  }

  .social-media-link {
    border: 1px solid $color-cherryred;
    background-color: $color-cherryred;
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: $color-offwhite
    }

    a {
      position: relative;
      top: 0.3rem;
      left: 0.3rem;
    }

    a:hover {
      color: $color-camo-green;
    }
  }
}

