.project-page {
  &__tab-navigation {
    padding-bottom: 30px;

    .nav-tabs {
      border: none;
      background: $color-camo-green;
      padding: 10px 0;
      @include media-breakpoint-up(lg) {
        padding: 0;
      }
      .nav-item {
        background: transparent;
        border: none;

        .nav-link {
          font-weight: bold;
          padding: 10px 25px;
          line-height: 1;
          color: white;
          background: transparent;
          border: none;
          @include media-breakpoint-up(lg) {
            padding: 20px 25px;
            font-size: 22px;
          }
          &:hover {
            color: $color-cherryred;
          }
          &.active {
            color: $color-cherryred;
          }
        }
      }
    }
  }
}

.project-info {
  margin: 30px 0;
  span.icon {
    width: 45px;
    height: 45px;
    svg {
      vertical-align: top;
      fill: $color-cherryred;
    }
  }
  &__name {
    font-size: 32px;
    font-weight: bold;
    color: $color-cherryred;
  }
  &__location {
    font-size: 22px;
    color: $color-camo-green;
  }
}

.project-briefing {
  margin: 30px 0 1px;
  border-radius: 5px;
  background: $color-offwhite;
  border: 1px solid darken($color-offwhite, 10);
  padding: 30px 0;
  font-size: 0.93rem;
  font-weight: bold;
  span.icon {
    width: 40px;
    height: 40px;
    margin-right: 7px;
    @include media-breakpoint-up(md) {
      width: 45px;
      height: 45px;
      margin-right: 0;
    }
    svg {
      vertical-align: top;
      fill: $color-cherryred;
    }
  }
  &__image{
    width: 45px !important;
    height: 40px;
    width: auto;
    margin-left: -5px;

    @include media-breakpoint-up(md) {
      width: 38px !important;
      height: 45px;
      margin-left: 0;
    }
    @include media-breakpoint-up(lg) {
      width: 45px !important;
    }
  }
  &__item{
    justify-content: center;
    &-info{
      width: 100%;
      //white-space: nowrap;
    }
    @media (max-width: 768px) {
      margin-bottom: 15px;
      justify-content: flex-start;
    }
    @media (max-width: 576px) {
      padding-left: 15%;
    }
    @media (min-width: 768px) and (max-width: 992px) {
      margin-bottom: 15px;
      &--first-item{
        padding-bottom: 15px;
        justify-content: flex-start;
      }
      &--last-item{
        justify-content: flex-end;
      }

    }
  }
}
